import {Component, OnInit, ViewChild} from '@angular/core';
import { WizardBudgetPlannerService } from "../../../WizardBudgetPlannerService";
import {CommonDataSingleton, ICommonDataSingleton} from "@expertasolutions/budgetplanner-ui-common";
import { MatStepper } from "@angular/material/stepper";
import {CompanyInfo} from "@expertasolutions/budgetplanner-ui-common";
import {WizardDataSingleton} from "../../../WizardDataSingleton";

@Component({
  selector: 'planSelector',
  styleUrls: [
    './planSelector.component.scss',
    '../../../../assets/landing/css/style.scss'
  ],
  templateUrl: './planSelector.component.html'
})
export class PlanSelectorComponent implements OnInit {
  @ViewChild(MatStepper) wizardCpm: MatStepper;

  public cmnSingleton: WizardDataSingleton = null;
  public aimAgree: boolean = false;
  public selectedPlanIdentifier: string;
  public currentCompany: CompanyInfo;

  constructor(private bpService:WizardBudgetPlannerService) { }

  async ngOnInit() {
    await this.bpService.init();
    this.bpService.changeCurrentCompany().then(() => {
      this.cmnSingleton = WizardDataSingleton.getInstance(this.bpService);
      this.bpService.getCurrentCompany().then(result=> {
        this.currentCompany = result;
      });
    });
  }

  public abort() {
    this.bpService.abort();
  }

  public planIdentifierChanged(evt) {
    this.selectedPlanIdentifier = evt;
  }

  public savePlanTier() {
    this.bpService.post(`/api/companyInfo/AssignSubscriptionPlan/${this.selectedPlanIdentifier}`, this.currentCompany)
      .then(result => {
        if(result.success) {
          this.currentCompany = result.data;
          this.wizardCpm.next();
        }
      });
  }

  public closeWizard() {
    this.bpService.post(`/api/CompanyInfo/AcceptSubscriptionPlan`, this.currentCompany)
      .then((result) => {
        if(result.success) {
          this.bpService.transfertToAdminPortal(result.data);
        }
      });
  }
}
