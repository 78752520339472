<div class="container">
  <nav class="navbar navbar-expand-lg navbar-light navbar-default navbar-fixed-top past-main" role="navigation">
    <div class="container justify-content-md-center">
      <a href="javascript:" class="navbar-brand page-scroll" style="background-color:white;">
        <img src="assets/landing/logos/logo.png" alt="BudgetPlanner.Cloud" />
      </a>
    </div>
  </nav>
</div>
<div class="main" id="main">
  <div class="hero-section app-hero">
    <div class="container">
      <div class="hero-content app-hero-content">
        <div class="row justify-content-md-center">
          <div class="col-md-12">
            <app-card cardTitle="Choose your subscription plan" [options]="false">
              <mat-horizontal-stepper #wizard class="arc-wizard">

                <mat-step label="Choose your Plan">
                  <div class="row">
                    <div class="col-sm-12">
                      <planWizard (selectedPlanIdentifierEvent)="planIdentifierChanged($event)"></planWizard>
                    </div>
                    <div class="col-sm-12 centered-content">
                      <div class="btn-group mt-10">
                        <button type="button" class="btn btn-danger btn-sm" (click)="abort()">Cancel</button>
                        <button type="button" class="btn btn-primary btn-sm" (click)="savePlanTier()">Continue</button>
                      </div>
                    </div>
                  </div>
                </mat-step>

                <mat-step label="Summary">
                  <div class="row">
                    <div class="col-sm-12" style="text-align: center;">
                      <h5>Complete my Organization Subscription Plan selection</h5>
                      <hr>
                      <p>
                        Please take some time to read the Terms & Conditions
                      </p>
                      <termsConditions></termsConditions>
                      <p>
                        <input type="checkbox" [(ngModel)]="aimAgree"/>&nbsp;I accept with all Terms & Conditions
                      </p>
                    </div>
                    <div class="col-sm-12 centered-content">
                      <div class="btn-group mt-10">
                        <button type="button" class="btn btn-danger btn-sm" (click)="abort()">Cancel</button>
                        <button type="button" class="btn btn-success btn-sm" (click)="closeWizard()" [disabled]="!this.aimAgree">Start using my Organization</button>
                      </div>
                    </div>
                  </div>
                </mat-step>

              </mat-horizontal-stepper>
            </app-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
